import {TableBase} from "../../tables/table_base";

class PotentialPenaltySummary extends TableBase {
    constructor() {
        super('potential_penalty_summary');
    }

    paintTable($el, data) {
        const $potentialPenalties = $('div#potential_penalty_summary');
        $potentialPenalties.find('#total_penalties').text(`£${data.total}`);
        for (const key in data.categories) {
            const infringements = data.categories[key];
            $potentialPenalties.find(`td#penalty_breakdown_${key}`).text(infringements);
        }
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new PotentialPenaltySummary();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});
